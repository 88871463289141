import * as React from "react"
import { Link } from "gatsby"
import {
} from "@chakra-ui/react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import PrivacyPolicy from '../components/PrivacyPolicy'

function SecondPage() {
  return (
    <Layout>
      <Seo title="Privacy policy" />
      <PrivacyPolicy />
      <Link to="/">Go back to the homepage</Link>
    </Layout>
  )
}

export default SecondPage
